import React, { Component } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import Carousel from "react-bootstrap/Carousel";
import { GatsbyImage } from "gatsby-plugin-image"

class Produtos extends Component {
    render() {
        return(
            <div className="featured-posts">
                  <Carousel indicators={false} interval={3000}>
                  {
                      this.props.data.allContentfulProduto.edges.map(({node: product, index}) => {
                          return(
                              <Carousel.Item key={product.urlProduto}>
                                <Link to={`/produtos/${product.urlProduto}/`} className="link link-iberica">
                                    <div className="post-item">
                                        <div className="overlay"></div>	
                                        <GatsbyImage className="image" objectFit="scale-down" image={product.imagemProduto.gatsbyImageData} alt={product.nomeProduto}/>
                                        <div className="title"><p>{product.nomeProduto}</p></div>
                                    </div>
                                  </Link>
                              </Carousel.Item>
                          )
                      })
                  }
                  </Carousel>
                </div>
        )
    }
 }

const QueryProdutos = () => (
    <StaticQuery
      query={graphql`
      query ProductsQuery {
        allContentfulProduto(
          filter: {
            imagemProduto: {description: {ne: null}}, 
            nomeProduto: {ne: null}, 
            urlProduto: {ne: null}}
        ) {
          edges {
            node {
              nomeProduto
              urlProduto
              imagemProduto {
                gatsbyImageData(
                  layout: FULL_WIDTH 
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  )
              }
            }
          }
        }
      }`}
      render={(data) => (
        <Produtos data={data} />
      )}
    />
  )

export default QueryProdutos  