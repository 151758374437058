import React from "react"
import Header from "../components/Header"
import "../styles.scss";
import Video from "../components/Home/Video";
import Valores from "../components/Home/Valores";
import Produtos from "../components/Home/Produtos";
import SobreNos from "../components/Home/SobreNos";
import PorQueIberica from '../components/Home/PorQueIberica';
import QualidadeMundial from "../components/Home/QualidadeMundial";
import Contato from "../components/Home/Contato";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
          <html lang="pt-BR" amp />
          <meta charSet="UTF-8" />
          <title>Cia. Ibérica</title>
          <link rel="canonical" href="https://www.ciaiberica.com.br" />
          <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large" />
          <meta property="og:locale" content="pt-BR" />
          <meta property="og:title" content="Cia. Ibérica" />
          <meta property="og:description" content="Confira os melhores cabos e produtos elétricos na Cia. Ibérica, empresa brasileira e com tradição no mercado." />
          <meta property="og:url" content="https://www.ciaiberica.com.br" />
          <meta property="og:sitename" content="Cia. Ibérica" />
          <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <Header />
      <Video />
      <Valores />
      <Produtos />
      <SobreNos />
      <PorQueIberica />
      <QualidadeMundial />
      <Contato />
      <Footer />
    </>
  )
}

export default IndexPage
