import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import EcoPlug from "../../svg/EcoPlug.svg";
import Award from "../../svg/Award.svg";
import Support from "../../svg/Support.svg";

export default class Valores extends Component {
    render() {
        return (
            <Container>
                <Row className="iberica-row-valores">
                    <div className="col-sm-4 slide-iberica iberica-div-slide slide-1" id="slide-1">
                        <Support className="iberica-svg-valores" />
                        <h4>Atendimento</h4>
                        <p>Atendimento personalizado de acordo com as necessidades dos nossos clientes.</p>
                    </div>
                    <div className="col-sm-4 slide-iberica iberica-div-slide slide-2" id="slide-2">
                        <Award className="iberica-svg-valores" />
                        <h4>Qualidade</h4>
                        <p>Todos os nossos produtos são certificados e homologados pelo INMETRO, proporcionando confiabilidade e segurança aos nossos clientes.</p>
                    </div>
                    <div className="col-sm-4 slide-iberica iberica-div-slide slide-3" id="slide-3">
                        <EcoPlug className="iberica-svg-valores" />
                        <h4>Sustentabilidade</h4>
                        <p>Temos como princípio ser uma empresa amiga do meio ambiente, buscando sempre soluções sustentáveis.</p>
                    </div>
                </Row>
            </Container>
        )
    }
}