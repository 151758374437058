import React, {Component} from 'react';
import { graphql, StaticQuery } from 'gatsby';

class Video extends Component {
    render() {
        return (
            <div className="iberica-div-video">
                <video className="video-iberica" autoPlay loop muted>
                        <source src={this.props.data.contentfulAsset.file.url}
                            type={this.props.data.contentfulAsset.file.contentType}/>
                </video>
            </div>
        )
    }
}

const QueryVideo = () => (
    <StaticQuery
      query={graphql`
      query VideoQuery {
        contentfulAsset(title: {eq: "video-inicial"}) {
            file {
              url
              contentType
            }
        }
      }
    `}
      render={(data) => (
        <Video data={data} />
      )}
    />
  )

export default QueryVideo